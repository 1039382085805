import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
  }

  closeDropdownMenu() {
    console.log('closeDropdownMenu !!!!');
  }
}
