import CommonDropdownMenuController from 'components/common/dropdown/menu_controller';
import { Controller } from '@hotwired/stimulus';

export default class CommonDropdownMiniController extends Controller {
  static values = { type: String };

  static targets = ['chevronUp', 'chevronDown'];

  static outlets = ['common--dropdown--menu'];

  closeDropdownMenu() {
    if (this.hasCommonDropdownMenuOutlet) {
      this.commonDropdownMenuOutlets.forEach((el) => {
        el.close();
      });
    }

    if(this.typeValue === 'chevron') {
      $(this.chevronUpTarget).removeClass('force-hide');
      $(this.chevronDownTarget).addClass('force-hide');
    }
  }

  // common/menu/dropdown
  declare typeValue: string;
  declare chevronUpTarget: HTMLElement;
  declare chevronDownTarget: HTMLElement;
  declare hasCommonDropdownMenuOutlet: boolean;
  declare commonDropdownMenuOutlets: CommonDropdownMenuController[];
}
