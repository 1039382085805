import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { selectedId: String, bindToId: String };

  connect() {
    $(this.element).on("click", "[js-common--radio-menu-item]", (event) => {
      console.log("Item clicked:", event.currentTarget);
      var id = $(event.currentTarget).data('id');

      // Only update if the new id is different from the current one
      if(this.selectedIdValue.toString() === id.toString()) {
        return;
      }

      this.selectedIdValue = id;
    });

    $(this.element).on("change", function(e) {
      console.log("Change event triggered", e.detail);
    });
  }

  setSelectedId(id) {
    this.selectedIdValue = id;
  }

  selectedIdValueChanged() {
    // When the value changes, we need to deselect all other radio buttons
    // and select the new one.
    $(this.element).find('[js-common--radio-menu-item]').each((_index, item) => {
      var checked = $(item).data('id').toString() === this.selectedIdValue.toString();

      if(checked) {
        $(item).attr('aria-checked', 'true');
        // Allow the checkmark to be displayed before the focus is set
        setTimeout(() => { $(item).find(".fa-check").removeClass("force-hide"); }, 0);
        $(item).attr("tabindex", "0");
      } else {
        $(item).attr('aria-checked', 'false');
        setTimeout(() => { $(item).find(".fa-check").addClass("force-hide"); }, 0);
        $(item).attr("tabindex", "-1");
      }
    });
    
    if(this.bindToIdValue) {
      $('#' + this.bindToIdValue).val(this.selectedIdValue);
    }

    // Dispatch the "change" event on the radiogroup
    this.element.dispatchEvent(new CustomEvent('change', {
      detail: { selectedId: this.selectedIdValue },
      bubbles: true,
    }));

    console.log("Selected ID changed to:", this.selectedIdValue);
  }

  addItem(data) {
    const template = $(this.element).find("template")[0];
    let new_item = $(template.content.cloneNode(true));
    new_item.find("[js-common--radio-menu-item]").attr("data-id", data['id']);
    new_item.find('[js-common--radio-menu-item-title]').text(data['title']);
    $(this.element).append(new_item);
  }

  declare selectedIdValue: string;
  declare bindToIdValue: string;
}
