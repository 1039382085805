import { Controller } from '@hotwired/stimulus';

export default class BaseController extends Controller {
  connect() {
    // Emit a custom event
    const event = new CustomEvent('components::connected', {
      detail: { controller: this },
      bubbles: true, // Allows the event to bubble up the DOM
      cancelable: true, // Event can be canceled if needed
    });

    this.element.dispatchEvent(event);

    // Call the original connect logic if needed
    if (super.connect) {
      super.connect();
    }
  }
}
