import c0 from './auth/fields/locale_controller';
import c1 from './base_controller';
import c2 from './common/buttons/button_controller';
import c3 from './common/buttons/button_group_controller';
import c4 from './common/card/disclosure_card_controller';
import c5 from './common/carousel/image_carousel_controller';
import c6 from './common/clipboard/button_controller';
import c7 from './common/counter/character_controller';
import c8 from './common/dropdown/link_controller';
import c9 from './common/dropdown/listbox_controller';
import c10 from './common/dropdown/menu_controller';
import c11 from './common/dropdown/mini_controller';
import c12 from './common/dropdown/nested_listbox_controller';
import c13 from './common/dropdown/radio_menu_controller';
import c14 from './common/header/logo_header_controller';
import c15 from './common/icons/file_extension_controller';
import c16 from './common/link/chevron_controller';
import c17 from './common/list/filterable_list_controller';
import c18 from './common/list/stream_list_controller';
import c19 from './common/menu/list_item_controller';
import c20 from './common/menu/vertical_menu_controller';
import c21 from './common/notification/alert_controller';
import c22 from './common/notification/banner_controller';
import c23 from './common/popover/help_controller';
import c24 from './common/popover/popover_controller';
import c25 from './common/progress/step_controller';
import c26 from './common/progress/steps_controller';
import c27 from './common/side_panel/sidebar_controller';
import c28 from './common/tabs/frame_controller';
import c29 from './common/tabs/tab_controller';
import c30 from './common/tabs/tabs_controller';
import c31 from './common/visualizations/mic_indicator_controller';
import c32 from './common/visualizations/progress_bar_controller';
import c33 from './common/visualizations/radial_progress_bar_controller';
import c34 from './conditional_actions/condition_row_controller';
import c35 from './conditional_actions/conditions_controller';
import c36 from './fields/date/date_picker_controller';
import c37 from './fields/password/password_field_controller';
import c38 from './fields/select/select_controller';
import c39 from './forms/search/input_filter_controller';
import c40 from './forms/search/multi_select_filter_controller';
import c41 from './forms/search/multi_select_filter_optimized_controller';
import c42 from './forms/search/multi_select_filter_simple_controller';
import c43 from './history/filters_bar_controller';
import c44 from './history/timeline_controller';
import c45 from './input/date/datetime_picker_controller';
import c46 from './live_interviews/forms/icon_button_controller';
import c47 from './navigation/linkbar/companies_launcher_controller';
import c48 from './navigation/navbar/button_controller';
import c49 from './navigation/navbar/navbar_controller';
import c50 from './navigation/navbar/search_controller';
import c51 from './notification/badge_controller';
import c52 from './notification/frame_controller';
import c53 from './profile/live_interview_item_controller';
import c54 from './profile/vidprofile_controller';
import c55 from './scoring_fields/field_property_toggle_controller';
import c56 from './scoring_fields/question_row_controller';
import c57 from './scoring_fields/questions_controller';
import c58 from './scoring_fields/scoring_scale_row_controller';
import c59 from './scoring_fields/scoring_scales_controller';
import c60 from './stats/chart_controller';
import c61 from './transcription/transcription_display_controller';
import c62 from './transcription/transcription_speaker_attribution_controller';
import c63 from './utils/reveal/button_controller';
import c64 from './utils/reveal/checkbox_controller';
import c65 from './utils/reveal/content_controller';
import c66 from './utils/reveal/radio_controller';
export const definitions = [
	{identifier: 'auth--fields--locale', controllerConstructor: c0},
	{identifier: 'base', controllerConstructor: c1},
	{identifier: 'common--buttons--button', controllerConstructor: c2},
	{identifier: 'common--buttons--button-group', controllerConstructor: c3},
	{identifier: 'common--card--disclosure-card', controllerConstructor: c4},
	{identifier: 'common--carousel--image-carousel', controllerConstructor: c5},
	{identifier: 'common--clipboard--button', controllerConstructor: c6},
	{identifier: 'common--counter--character', controllerConstructor: c7},
	{identifier: 'common--dropdown--link', controllerConstructor: c8},
	{identifier: 'common--dropdown--listbox', controllerConstructor: c9},
	{identifier: 'common--dropdown--menu', controllerConstructor: c10},
	{identifier: 'common--dropdown--mini', controllerConstructor: c11},
	{identifier: 'common--dropdown--nested-listbox', controllerConstructor: c12},
	{identifier: 'common--dropdown--radio-menu', controllerConstructor: c13},
	{identifier: 'common--header--logo-header', controllerConstructor: c14},
	{identifier: 'common--icons--file-extension', controllerConstructor: c15},
	{identifier: 'common--link--chevron', controllerConstructor: c16},
	{identifier: 'common--list--filterable-list', controllerConstructor: c17},
	{identifier: 'common--list--stream-list', controllerConstructor: c18},
	{identifier: 'common--menu--list-item', controllerConstructor: c19},
	{identifier: 'common--menu--vertical-menu', controllerConstructor: c20},
	{identifier: 'common--notification--alert', controllerConstructor: c21},
	{identifier: 'common--notification--banner', controllerConstructor: c22},
	{identifier: 'common--popover--help', controllerConstructor: c23},
	{identifier: 'common--popover--popover', controllerConstructor: c24},
	{identifier: 'common--progress--step', controllerConstructor: c25},
	{identifier: 'common--progress--steps', controllerConstructor: c26},
	{identifier: 'common--side-panel--sidebar', controllerConstructor: c27},
	{identifier: 'common--tabs--frame', controllerConstructor: c28},
	{identifier: 'common--tabs--tab', controllerConstructor: c29},
	{identifier: 'common--tabs--tabs', controllerConstructor: c30},
	{identifier: 'common--visualizations--mic-indicator', controllerConstructor: c31},
	{identifier: 'common--visualizations--progress-bar', controllerConstructor: c32},
	{identifier: 'common--visualizations--radial-progress-bar', controllerConstructor: c33},
	{identifier: 'conditional-actions--condition-row', controllerConstructor: c34},
	{identifier: 'conditional-actions--conditions', controllerConstructor: c35},
	{identifier: 'fields--date--date-picker', controllerConstructor: c36},
	{identifier: 'fields--password--password-field', controllerConstructor: c37},
	{identifier: 'fields--select--select', controllerConstructor: c38},
	{identifier: 'forms--search--input-filter', controllerConstructor: c39},
	{identifier: 'forms--search--multi-select-filter', controllerConstructor: c40},
	{identifier: 'forms--search--multi-select-filter-optimized', controllerConstructor: c41},
	{identifier: 'forms--search--multi-select-filter-simple', controllerConstructor: c42},
	{identifier: 'history--filters-bar', controllerConstructor: c43},
	{identifier: 'history--timeline', controllerConstructor: c44},
	{identifier: 'input--date--datetime-picker', controllerConstructor: c45},
	{identifier: 'live-interviews--forms--icon-button', controllerConstructor: c46},
	{identifier: 'navigation--linkbar--companies-launcher', controllerConstructor: c47},
	{identifier: 'navigation--navbar--button', controllerConstructor: c48},
	{identifier: 'navigation--navbar--navbar', controllerConstructor: c49},
	{identifier: 'navigation--navbar--search', controllerConstructor: c50},
	{identifier: 'notification--badge', controllerConstructor: c51},
	{identifier: 'notification--frame', controllerConstructor: c52},
	{identifier: 'profile--live-interview-item', controllerConstructor: c53},
	{identifier: 'profile--vidprofile', controllerConstructor: c54},
	{identifier: 'scoring-fields--field-property-toggle', controllerConstructor: c55},
	{identifier: 'scoring-fields--question-row', controllerConstructor: c56},
	{identifier: 'scoring-fields--questions', controllerConstructor: c57},
	{identifier: 'scoring-fields--scoring-scale-row', controllerConstructor: c58},
	{identifier: 'scoring-fields--scoring-scales', controllerConstructor: c59},
	{identifier: 'stats--chart', controllerConstructor: c60},
	{identifier: 'transcription--transcription-display', controllerConstructor: c61},
	{identifier: 'transcription--transcription-speaker-attribution', controllerConstructor: c62},
	{identifier: 'utils--reveal--button', controllerConstructor: c63},
	{identifier: 'utils--reveal--checkbox', controllerConstructor: c64},
	{identifier: 'utils--reveal--content', controllerConstructor: c65},
	{identifier: 'utils--reveal--radio', controllerConstructor: c66},
];
