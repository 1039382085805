import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values  = { muted: Boolean, level: Number, small: Boolean };
  static targets = ['mutedIcon', 'unmutedIcon', 'level'];

  connect() {
    console.log("Level controller connected");
  }

  mutedValueChanged() {
    console.log("mutedValueChanged");
    if (this.mutedValue) {
      this.mutedIconTarget.classList.remove('force-hide');
      this.unmutedIconTarget.classList.add('force-hide');
      this.levelTarget.classList.add('force-hide');
    } else {
      this.unmutedIconTarget.classList.remove('force-hide');
      this.levelTarget.classList.remove('force-hide');
      this.mutedIconTarget.classList.add('force-hide');
    }
  }

  levelValueChanged() {
    if(this.smallValue) {
      var height = (this.levelValue / 100) * $(this.element).outerHeight();
      var width = $(this.element).outerWidth();
    } else {
      var width = (this.levelValue / 100) * $(this.element).outerWidth();
      var height = $(this.element).outerHeight();
    }

    this.levelTarget.style.height = `${height}px`;  
    this.levelTarget.style.width = `${width}px`;  
  }
}
