import BaseController from '@components/base_controller';

export default class extends BaseController {
  static values = { type: String, states: Object, state: String, tabId: String, tabGroupId: String };

  static targets = ['icon', 'button', 'label'];

  connect() {
    super.connect();
  }

  tabclick() {
    // find all other tabs in the same group and remove the active for the tabs.

    $(`[data-live-interviews--forms--icon-button-tab-group-id-value="${this.tabGroupIdValue}"]`).each(function() {
      const controller = $.getStimulus($(this));
      controller.tabDeselect();
    });

    this.tabSelect();
  }

  tabPanel() {
    return $(`[role='tabpanel']#${this.tabIdValue}`);
  }

  tabDeselect() {
    this.clearState();

    const tab_panel = this.tabPanel();
    tab_panel.addClass('force-hide');
    tab_panel.attr('aria-selected', 'false');
    tab_panel.attr('aria-hidden', 'true');
    this.buttonTarget.setAttribute('aria-selected', 'false');    
    
    console.log("!! tab deselect !!");
  }

  tabSelect() {
    this.setState('active');

    const tab_panel = this.tabPanel();
    tab_panel.removeClass('force-hide');
    tab_panel.attr('aria-selected', 'true');
    tab_panel.attr('aria-hidden', 'false');
    this.buttonTarget.setAttribute('aria-selected', 'true');    
  }

  stateValueChanged(newValue, oldValue) {
    const states_data = this.statesValue;

    if (this.statesValue[this.stateValue]) {
      $(this.iconTarget).find(`[data-js-state-icon-type]`).addClass("force-hide");
      if(oldValue && states_data[oldValue]) {
        $(this.buttonTarget).removeClass(states_data[oldValue].button_classes || "");
      }
      $(this.iconTarget).find(`[data-js-state-icon-type="${newValue}"]`).removeClass("force-hide");
      $(this.buttonTarget).attr("title", states_data[newValue].title).addClass(states_data[newValue].button_classes || "");
      $(this.labelTarget).text(states_data[newValue].label);
    }

    $.onmount();
  }

  setState(state) {
    this.stateValue = state;
  }

  clearState() {
    this.stateValue = 'default';
  }
}
